<template>
	<main
		class="container-white"
		:class="{'mobile-container': mobileCheck()}"
	>
		<section class="page-coverage container-base">
			<Breadcrumbs
				claass="page-coverage__breadcrumbs"
				v-if="device !== 'mobile'"
			/>

			<div class="page-coverage__grid">

				<div class="page-coverage__left-column">
					<h2 class="page-coverage__title">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="16"
							height="14"
							fill="none"
							@click="$router.go(-1); $store.dispatch('openPopup', false);"
						>
							<path
								fill="#3D4248"
								fill-rule="evenodd"
								d="M15 6H3.13l3.64-4.36A1 1 0 1 0 5.23.36l-5 6-.09.15-.07.13A.99.99 0 0 0 0 7c0 .13.03.25.07.36l.07.13c.03.05.05.1.1.15l5 6a1 1 0 1 0 1.53-1.28L3.14 8H15a1 1 0 0 0 0-2Z"
								clip-rule="evenodd"
							/>
						</svg>
						География покрытия
					</h2>
					<div class="page-coverage__address">
						<SearchMainPopup
							class="page-coverage__search"
							@selectAddress="goToMenu"
							:isAvailable="notificationType"
							:onlyClosePopup="true"
						/>

						<UnavailableAddress
							class="page-coverage__unavailable"
							v-if="notificationType"
							:type="notificationType"
						/>

						<Map
							class="page-coverage__map"
							@selectAddress="handleCurrentAddress()"
							:mapid="mapId"
							:isAvailable="notificationType"
						/>
					</div>

					<p class="page-coverage__text">
						Мы и наши партнеры стремимся улучшать качество работы и всегда благодарны за ваши отзывы и
						предложения. <span
						@click="showFeedbackPopup = true"
						class="page-coverage__text-highlight"
					>Пишите нам</span>,
						все рассмотрим, если нужно, ответим, сделаем выводы, примем меры.
					</p>

				</div>

				<div
					class="page-coverage__right-column"
					v-if="device !== 'mobile'"
				>
					<RightMenu
						:items="[
                                  {name: 'О сервисе', link: '/about', external: true},
					              {name: 'Как сделать заказ', link: '/about/howto', external: true},
					              {name: 'Скидки и баллы', link: '/about/bonus', external: true},
					              {name: 'Оплата и доставка', link: '/about/delivery', external: true},
					              {name: 'Новости и статьи', link: '/news', external: true},
					              {name: 'География покрытия', link: 'coverage'},
					              {name: 'Рецепты', link: '/recipes', external: true},
					              {name: 'Юридические документы', link: '/policies', external: true},
                                ]"
					/>
				</div>
			</div>
		</section>

		<Popup :visible.sync="showFeedbackPopup">
			<Feedback @closePopup="showFeedbackPopup = false"/>
		</Popup>
		<Popup :visible="openPopupName === selectRestauantPopupName">
			<Restaurants
				:restaurants="restaurants"
				@selectRestaurant="choseRest"
			/>
		</Popup>
	</main>
</template>

<script>
import Map from '@/components/block/Map.vue';
import SearchMainPopup from '../components/form/SearchMainPopup.vue';
import search from "@/mixins/search";
import {mapGetters, mapState} from "vuex";
import UnavailableAddress from '../components/block/UnavailableAddress.vue';
import {useYandexMetrika} from "@/composables";
import {mobileCheck} from "@/helpers";

export default {
	name: 'CoveragePage',
	setup()
	{
		const {yandexMetrika} = useYandexMetrika();

		return {yandexMetrika};
	},
	mixins: [search],
	components: {
		Breadcrumbs: () => import('../components/nav/Breadcrumbs.vue'),
		UnavailableAddress,
		SearchMainPopup,
		Map,
		RightMenu: () => import('../components/nav/RightMenu.vue'),
		Feedback: () => import('../components/form/Feedback.vue'),
		Restaurants: () => import('../components/popup/Restaurants.vue'),
	},
	data()
	{
		return {
			mapId: 'coverageMap',
			iconPlacemarkAvailable: true,
			showFeedbackPopup: false,
			selectRestauantPopupName: 'SelectRestauantPopup',
		}
	},
	computed: {
		...mapGetters({
			openPopupName: 'getOpenPopupName',
			notificationType: 'getMapPopupNotificationType',
			currentRestaurantId: 'getRestarauntId',
			deliveryAddress: 'map/getDeliveryAddress',
			deliveryType: 'order/currentDeliveryType'
		}),

		...mapState({device: 'ui/type'})
	},
	methods:
		{
			mobileCheck,
			choseRest(restaurant)
			{
				this.$store.dispatch('openPopup', null);
				if (restaurant.id)
				{
					this.selectRestaurant(restaurant.id);
					if (this.$route.path !== "/menu") this.$router.push('/menu');
				}
			},
			close()
			{
				this.$store.dispatch('openPopup', null);
				this.$emit('update:visible', false);
				this.$el.remove();
			},

			async handleCurrentAddress()
			{
				if (this.notificationType === 'no-rests')
				{
					await this.$store.dispatch('changeInvalidAddress', this.deliveryAddress);
					this.iconPlacemarkAvailable = false;
				} else
				{
					this.iconPlacemarkAvailable = true;
				}
			},

			/**
			 * Срабатывает когда внутри карты меняется адрес
			 */
			async selectMapAddress(address)
			{
				this.notificationType && this.$store.dispatch('mapPopupNotificationTypeAction', false);
			},

			/**
			 * Срабатывает когда пользователь определился с адресом
			 */
			selectInputAddress(address)
			{
				this.$emit('selectAddress', address);
			},

			async goToMenu()
			{
				if (this.currentAddressCity && this.currentAddressCity.UF_XML_ID !== this.currentCityId)
				{
					await this.$store.dispatch('changeCity', this.currentAddressCity);
				}

				if (this.$store.state.map.currentAddressPrecision !== 'exact')
				{
					this.$store.dispatch('openPopup', this.addressMapPopupName);
					return;
				}

				let res = await this.setRestaurants();

				switch (res)
				{
					case 'noRest':
						this.$store.dispatch('changeInvalidAddress', this.address);
						this.$store.dispatch('openPopup', this.addressMapPopupName);
						break;
					case 'oneRest':
						this.$store.dispatch('openPopup', null);
						if (this.$route.path !== "/menu") this.$router.push('/menu');
						break;
					case 'severalRest':
						this.$store.dispatch('openPopup', this.selectRestauantPopupName);
						break;
					case false:
						this.$store.dispatch('openPopup', this.addressMapPopupName);
						break;
				}
			},
		},

	watch: {
		'$store.state.map.fullZones'()
		{
			this.$store.commit('map/SET_MAP_UPDATE', true);
		}
	},
	/**
	 * утснавливает выбранный ранее адрес
	 */
	mounted()
	{
		this.yandexMetrika();
		this.selectMapAddress();
		this.initialActions();
		this.setAddressIfCookieExist();
	}
}
</script>

<style lang="scss">

.page-coverage
{
	&__grid
	{
		display: grid;
		grid-template-columns: 910px 354px;
		gap: 0px 56px;
		width: 100%;
	}

	&__right-column
	{
		box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08);
		border-radius: 16px;
		border: 1px solid $greyBorder;
		height: 458px;
		margin-top: 22px;
	}

	&__title
	{
		line-height: 44px;
		border-bottom: 1px solid $greyBorder;
		margin-bottom: 24px;

		svg
		{display: none;}
	}

	&__address
	{
		display: flex;
		flex-direction: column;
	}

	&__search
	{
		position: relative;

		.search-label
		{
			box-shadow: none;
			background: $greyBackground;
			position: relative;
		}

		.search-main-popup__suggestions
		{
			width: 100%;
			left: 0;
		}
	}

	&__map
	{
		width: 100%;
		height: 560px;
		margin-bottom: 32px;

		.map__controls
		{
			bottom: 58px;
			top: unset;
		}
	}

	&__text
	{
		font-size: 16px;
		color: $greyLightText;
		margin-bottom: 72px;
		line-height: 24px;

		&-highlight
		{
			color: $green;
			text-decoration: underline;
			cursor: pointer;
		}
	}

	@media (max-width: 1369px)
	{

		&__grid
		{
			display: block;
		}

		&__right-column
		{
			display: none;
		}

		&__search
		{
			margin-bottom: 24px;
			flex-direction: row;
			height: 56px;

			.search__btn
			{
				margin-left: 16px;
			}
		}

	}

	@media (max-width: 567px)
	{

		&__grid
		{
			display: block;
		}

		.breadcrumbs
		{
			display: none;
		}

		&__right-column
		{
			display: none;
		}

		&__title
		{
			display: flex;
			align-items: center;
			font-size: 24px;
			border: none;
			margin-bottom: 16px;

			svg
			{
				display: block;
				cursor: pointer;
				margin-right: 12px;
			}
		}

		&__address
		{
			display: flex;
			flex-direction: column-reverse;
			position: relative;
		}

		&__map
		{
			margin-bottom: 12px;
			height: 340px;

			.map__controls
			{
				right: 8px;
				bottom: 8px;
			}
		}

		&__unavailable
		{
			width: 100%;
			position: absolute;
			top: 0px;
			z-index: 100;
		}

		&__search
		{
			margin-bottom: 32px;
			flex-direction: column;
			height: auto;

			.search__btn
			{
				margin-left: 0px;
				width: 100%;
			}

			.search__btn_no-shadow
			{
				min-width: unset;
			}
		}

		&__text
		{
			font-size: 14px;
		}

	}

	@media (max-width: 340px)
	{


		&__title
		{
			font-size: 20px;
		}
	}
}

</style>
