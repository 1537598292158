<template>
	<div
		class="notification"
		:class="notification[type].class"
	>
		<img
			class="notification_icon"
			:src="notification[type].icon"
		/>
		<div class="notification_text">{{ notification[type].text }}</div>
		<div
			class="notification_btn-block"
			v-if="notification[type].btnText"
			@click="handleClick"
		>{{ notification[type].btnText }}
			<img
				class="notification_btn-block_arrow"
				src="~@/assets/img/arrow.svg"
			/>
		</div>
	</div>
</template>

<script>
import search from '@/mixins/search.js';

export default {
	mixins: [search],
	props: ['type'],
	data()
	{
		return {
			notification: {
				"no-rests": {
					icon: require('@/assets/img/alertTriangleGreen.svg'),
					text: "К сожалению, мы пока не можем привезти еду по данному адресу, сообщить когда сможем?",
					btnText: "Да, уведомите меня!",
					class: "green",
				},
				"only-tomorrow": {
					icon: require('@/assets/img/alertTriangleYellow.svg'),
					text: "Кухня откроется завтра",
					btnText: "Заказать на завтра",
					class: "yellow",
				},
				"on-weekend": {
					icon: require('@/assets/img/alertTriangleYellow.svg'),
					text: "Кухня откроется после выходных",
					btnText: "",
					class: "yellow",
				}
			},
			openSubscribePopupName: "OpenSubscribePopup",
		}
	},
	methods: {
		async handleClick()
		{
			if (this.type === "no-rests")
			{
				this.$store.dispatch('openPopup', 'OpenSubscribePopup');
			} else if (this.type === "only-tomorrow")
			{
				this.$store.dispatch('setDate', 'tomorrow');
				let res = await this.setRestaurants();
				if (res === 'oneRest')
				{
					this.$store.dispatch('openPopup', null);
					if (this.$route.path !== "/menu") this.$router.push('/menu');
				} else if (res === 'severalRest')
				{
					this.$store.dispatch('openPopup', 'SelectRestauantPopup');
				}
			}
		},
	}
}
</script>

<style lang="scss">
.notification
{
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 8px;
	width: 100%;
	margin: 9px auto;

	&.green
	{
		background: #F0F8F0;
	}

	&.yellow
	{
		background: #FEF8E9;
	}

	box-shadow: 0px 8px 20px rgba(33, 36, 41, 0.15);
	border-radius: 8px;

	&_text
	{
		max-width: 450px;
		width: 100%;
		font-family: $mainFontMediumNew;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		display: flex;
		align-items: center;
		color: #3D4248;
		flex-grow: 0;
		margin: 0 13px;
	}

	&_btn-block
	{
		cursor: pointer;
		font-family: $mainFontBoldNew;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		line-height: 20px;
		color: #40AE49;
		align-items: center;
		margin-left: auto;
		display: flex;
		white-space: nowrap;

		&:hover
		{
			text-decoration: underline;
		}

		&_arrow
		{
			width: 24px;
			height: 24px;
			justify-content: space-between;
		}
	}
}

@media (max-width: 990px)
{
	.notification
	{
		max-width: 560px;

		&_text
		{
			font-weight: 500;
		}

		&_icon
		{
			margin: 0;
		}
	}
}

/*@media (max-width: 567px) {
  .notification {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 8px;
    max-width: 311px;
    position: relative;

    &_icon {
      position: absolute;
      left: 9px;
      top: 16px;
      margin-right: auto;
    }

    &_text {
      max-width: 260px;
      min-height: 42px;
      font-size: 12px;
      line-height: 14px;
      font-weight: 500  ;
      margin-top: 8px;
      margin-bottom: 8px;
      margin-left: 48px;
    }

    &_btn-block {
      line-height: 16px;
      text-align: center;
      margin: 5px 0;

      &_arrow {
        display: none;
      }
    }
  }
}*/

@media (max-width: 567px)
{

	.notification
	{
		max-width: 100vw;
		padding: 0;
		margin-bottom: 0;
		margin-top: 0;
		margin-left: 0;
		margin-right: 0;
		width: 100vw;
		flex-direction: column;
		justify-content: center;

		&_icon
		{ display: none; }

		&_text
		{
			width: 100%;
			max-width: 100%;
			height: 50px;
			padding: 5px 24px;
			text-align: center;
			margin: 0;
			padding-bottom: 8px;
			font-size: 11px;
			border-bottom: 1px solid rgba(60, 60, 67, 0.29);
		}

		&_btn-block
		{
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 35px;
			line-height: 13px;
			padding: 0;
			font-size: 13px;
			text-align: center;

			&_arrow
			{
				display: none;
			}
		}
	}

}
</style>
